<template>
  <div class="main">
    <div class="res-info-panel">
      <van-image
        fit="cover"
        class="content-header"
        :src="require('../../static/images/success.png')"
      />
      <div>
        <span class="res-info-panel-lab">兑换成功！</span>
      </div>
    </div>

    <div class="res-content">
      <div class="res-content-row">
        <div class="res-content-row-title">订单编号：</div>
        <div class="res-content-row-lab">{{ $route.query.orderId }}</div>
      </div>

      <div class="res-content-row">
        <div class="res-content-row-title">支付分值：</div>
        <div class="res-content-row-lab">{{ $route.query.cyPoints }}</div>
      </div>

      <div class="res-content-row">
        <div class="res-content-row-title">支付账户：</div>
        <div class="res-content-row-lab">{{ mobile }}</div>
      </div>

      <div class="res-content-row">
        <div class="res-content-row-title">兑换商品：</div>
        <div class="res-content-row-lab">{{ $route.query.productTip }}</div>
      </div>

      <div class="res-content-row">
        <div class="res-content-row-title">查看立减金：</div>
        <div class="res-content-row-lab">微信->我->卡包->券和礼品卡查看</div>
      </div>

      <div class="res-content-row">
        <div class="res-content-row-title">使用方式：</div>
        <div class="res-content-row-lab">微信支付选择优惠立减</div>
      </div>
    </div>
    <div class="content">
      <div class="content-submit">
        <van-button class="content-btn" round
              color="linear-gradient(to right, #fab768, #fe6831)" @click="handleBack">
          确定
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

import {
  Row,
  Col,
  NavBar,
  Image,
  Button,
  Field,
  Popup,
  Step,
  Steps,
} from "vant";
import {
  memberInfo,
  queryCmccBalance,
  getProduct,
  placeOrder,
  dectOrder,
} from "@/api/global";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [Button.name]: Button,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },

  data() {
    return {
      showResPopup: true,
      channel: "",
      mobile: null,
    };
  },
  created() {
    if (/micromessenger/.test(window.navigator.userAgent.toLowerCase())) {
      this.channel = "使用微信";
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
      this.channel = "使用支付宝";
    } else {
      this.channel = "";
    }

    this.mobile = localStorage.getItem("ljj_mobile") || null;
  },
  mounted() {},
  methods: {
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.$route.query.orderId, // 需要转换为二维码的内容
        width: 170,
        height: 170,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },

    handleBack() {
      let query = {};
      if (this.$route.query.channelCode) {
        query.channelCode = this.$route.query.channelCode;
      }

      if (this.$route.query.merId) {
        query.merId = this.$route.query.merId;
      }

      if (this.$route.query.storeId) {
        query.storeId = this.$route.query.storeId;
      }

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }

      if (this.$route.query.ddback) {
        query.ddback = this.$route.query.ddback;
      }

      this.$router.replace({
        path: "/exchange",
        query,
      });

      // if (query.ddback) {
      //   window.location.href = `${decodeURIComponent(query.ddback)}`;
      // } else {
      //   this.$router.replace({
      //     path: "/info",
      //     query,
      //   });
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  min-height: 100vh;
  background-color: #fff;
}

:deep(.van-nav-bar__content) {
  background-color: #006bd9;
}

:deep(.van-nav-bar__title) {
  color: #fff !important;
}

:deep(.van-icon) {
  color: #fff !important;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-header {
    width: 80px;
    padding-top: 20px;
    padding-bottom: 15px;
  }

  &-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    padding: 15px 0 0;
  }

  &-descr {
    font-size: 13px;
    font-weight: 600;
    color: #666;
    padding: 15px 50px;
    text-align: center;
  }

  &-submit {
    padding-top: 30px;
  }

  &-btn {
    width: 250px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
  }
}

.res-header {
  width: 100%;
  min-height: 88px;
  background-color: #006bd9;
}

.res-info-panel {
  width: 100%;
  padding-top: 40px;
  min-height: 80px;
  margin-top: -1px;
  text-align: center;

  &-lab {
    width: 100%;
    font-size: 22px;
    color: #333;
  }
}

.qrcode-panel {
  margin: 15px;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid #ddd;
}

.qrcode {
  display: inline-block;

  img {
    width: 132px;
    height: 132px;
    background-color: #fff; //设置白色背景色

    box-sizing: border-box;
  }
}

.res-content {
  margin: 20px 15px;

  &-row {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding-top: 10px;

    &-title {
      color: #666;
    }

    &-lab {
      color: #333;
    }
  }
}
</style>
